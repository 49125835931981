import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioButton"]
  static values = { checked: String }

  notify(event) {
    this.checkedValue = event.target.value
  }

  checkedValueChanged() {
    this.radioButtonTargets.forEach((element) => {
      if (element.value == this.checkedValue) {
        this.dispatch("check", { target: element })
      } else {
        this.dispatch("uncheck", { target: element })
      }
    })
  }
}
