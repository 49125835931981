import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  push(event) {
    dataLayer.push({
      pet_owner_id: Current.petOwner?.id,
      ...event.params.event,
    })
  }

  purchase(event) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      pet_owner_id: Current.petOwner?.id,
      event: "purchase",
      ecommerce: event.params.event,
    })
  }
}
