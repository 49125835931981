import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "image", "video"]

  previewImage() {
    const file = this.inputTarget.files[0]

    if (file) {
      this.imageTarget.src = URL.createObjectURL(file)
      this.imageTarget.onload = () => {
        URL.revokeObjectURL(this.imageTarget.src)
      }
    }
  }

  previewVideo() {
    const file = this.inputTarget.files[0]

    if (file) {
      this.videoTarget.src = URL.createObjectURL(file)
      this.videoTarget.classList.remove("hidden")
      this.videoTarget.onload = () => {
        URL.revokeObjectURL(this.videoTarget.src)
      }
    }
  }
}
