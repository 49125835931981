class Current {
  get petOwner() {
    const id = this.extractContentFromMetaTag("current-pet-owner-id")

    if (id) {
      return { id }
    }
  }

  extractContentFromMetaTag(name) {
    return document.head
      .querySelector(`meta[name="${name}"]`)
      ?.getAttribute("content")
  }
}

window.Current = new Current()
