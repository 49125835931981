import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog", "image", "video"]

  open(event) {
    this.dialogTarget.showModal()
    const url = event.target.closest("a").href

    if (event.params.type == "video") {
      this.videoTarget.src = url
      this.videoTarget.removeAttribute("hidden")
      this.imageTarget.setAttribute("hidden", true)
    } else {
      this.imageTarget.src = url
      this.imageTarget.removeAttribute("hidden")
      this.videoTarget.setAttribute("hidden", true)
    }
  }

  close() {
    this.imageTarget.src = ""
    this.imageTarget.setAttribute("hidden", true)

    this.videoTarget.src = ""
    this.videoTarget.setAttribute("hidden", true)

    this.dialogTarget.close()
  }
}
