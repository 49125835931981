import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    publishableKey: String,
    sessionClientSecret: String,
  }

  async connect() {
    const checkout = await this.stripe.initEmbeddedCheckout({
      clientSecret: this.sessionClientSecretValue,
    })
    checkout.mount(this.element)
  }

  disconnect() {
    this.element.remove()
  }

  get stripe() {
    return Stripe(this.publishableKeyValue)
  }
}
