import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["visibility"]

  show(event) {
    this.visibilityTargets.forEach((element) =>
      element.removeAttribute("hidden"),
    )
  }

  hide(event) {
    this.visibilityTargets.forEach((element) =>
      element.setAttribute("hidden", true),
    )
  }
}
