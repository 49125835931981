import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    apiHost: { type: String, default: "api.kenall.jp" },
    publicApiKey: String,
  }

  static targets = ["postalCode", "prefecture", "city", "line1"]

  async complete() {
    const postalCode = this.postalCodeTarget.value
    const headers = {
      Authorization: `Token ${this.publicApiKeyValue}`,
    }

    const response = await fetch(
      `https://${this.apiHostValue}/v1/postalcode/${postalCode}`,
      { headers, mode: "cors" },
    )
    if (!response.ok) {
      return
    }

    const json = await response.json()

    // 複数返ってくる場合があるが、適当に最初のを使う。
    const address = json.data[0]
    this.prefectureTarget.value = address["prefecture"]
    this.cityTarget.value = address["city"]
    this.line1Target.value = address["town"]
  }
}
